class SwipperCustom {
  constructor() {
    this._swipperLinea();
    this._SwiperVertical();
    this._SwiperHorizontal();
    this._SwiperOneImage();
    this._SwiperOneImageModal();
  }
  _swipperLinea() {


    // Inicializa el modal
    $('#modal-cazu').on('shown.bs.modal', function () {
      var swiper = new Swiper(".swiperLinea", {
        slidesPerView: "auto",
        spaceBetween: 0,
        grabCursor: true,
        loop: false,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },


      });
    });

    // Destruye el modal
    $('#modal-cazu').on('hidden.bs.modal', function () {
      var mySwiper = new Swiper('.swiperLinea');
      mySwiper.destroy();
    });





    //resetSwiper();
    //swiper.update();
  }

  _SwiperVertical() {
    var swiper;

    function initSwiper() {
      swiper = new Swiper(".SwiperVertical", {
        slidesPerView: 1,
        spaceBetween: 0,
        autoHeight: true,
        grabCursor: true,
        loop: false,
        lazy: false,
        initialSlide: 0,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        breakpoints: {
          991: {
            slidesPerView: "auto",
            spaceBetween: 0,
            autoHeight: false,
          },
        },
      });
    }

    function updateSlideWidth() {
      var slides = document.querySelectorAll('.SwiperVertical .swiper-slide');
      slides.forEach(function (slide) {
        var image = slide.querySelector('img');
        if (image) {
          var aspectRatio = image.naturalWidth / image.naturalHeight;
          var slideHeight = parseFloat(getComputedStyle(slide).height);
          var slideWidth = slideHeight * aspectRatio;
          if (slideWidth > image.naturalWidth) {
            slideWidth = image.naturalWidth;
          }
          slide.style.width = slideWidth + 'px';
        }
      });
      if (swiper) {
        swiper.update(); // Actualizar el swiper solo si existe
      }
    }

    // Inicializar Swiper al cargar la página si existe el elemento .SwiperVertical
    var swiperElement = document.querySelector('.SwiperVertical');
    if (swiperElement) {
      initSwiper();
      updateSlideWidth();
    }

    // Reinicializar Swiper y actualizar el ancho de los slides al hacer resize si existe el elemento .SwiperVertical
    window.addEventListener('resize', function () {
      if (swiperElement) {
        swiper.destroy();
        initSwiper();
        setTimeout(function () {
          updateSlideWidth();
        }, 500); // Esperar 500ms antes de actualizar el ancho de los slides
      }
    });
  }

  _SwiperHorizontal() {
    var swiper = new Swiper(".SwiperHorizontal", {
      slidesPerView: 1,
      spaceBetween: 0,
      grabCursor: true,
      initialSlide: 0,
      
      loop: false,
      lazy: false,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },

      breakpoints: {
        767: {
          slidesPerView: 1.1,
        },
        991: {
          slidesPerView: 1.2,
        },
      },
    });

  }

  _SwiperOneImage() {
    var swiper = new Swiper(".SwiperOneImage", {
      slidesPerView: 1,
      spaceBetween: 0,
      grabCursor: true,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
    });
  }

  _SwiperOneImageModal() {

    // Inicializa el modal
    $('.modal-woo').on('shown.bs.modal', function () {
      var swiper = new Swiper(".SwiperOneImageModal", {
        slidesPerView: 1,
        spaceBetween: 0,
        grabCursor: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      });
    });
  }

}